<template>
  <div class="main-con">
    <div class="image-con">
      <img src="https://cq-img-hgz.snzfnm.com/bindCode_cq_banner.jpg" />
    </div>
    <div class="showBindCode">
      <input
        disabled="disabled"
        v-model="labId"
        placeholder="合格证编码"
        type="text"
      />
    </div>
    <div class="showBindCode">
      <input
        maxlength="5"
        v-model="securityCode"
        placeholder="刮开图层输入四位防伪码"
        type="text"
      />
    </div>
    <div class="searchBtn">
      <p @click="chaxunHand()">查询</p>
    </div>
    <van-popup round v-model="show">
      <div v-if="type == 1" class="popup-con suc">
        <img :src="require(`@/assets/images/suc.png`)" alt="" />
        <h1>您当前的产品是正品</h1>
        <h1>感谢您对我们的支持</h1>
        <van-button
          @click="backChange"
          color="#12D8B0"
          round
          size="large "
          class="primary"
          type="primary"
          >返回</van-button
        >
      </div>
      <div v-if="type == 2" class="popup-con fail">
        <img :src="require(`@/assets/images/fail.png`)" alt="" />
        <h1>没有找到这件产品</h1>
        <h1>抱歉，我们没有找到这件产品</h1>
        <van-button
          @click="backChange"
          color="#12D8B0"
          round
          size="large "
          class="primary"
          type="primary"
          >返回</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import cateApi from "@/request/api/hegezhen";
export default {
  components: {},
  data() {
    return {
      active: 0,
      labId: "",
      securityCode: "",
      certificateCode: "",
      show: false,
      type: 0,
    };
  },
  mounted() {
    let item = this.$route.query.params;
    item = JSON.parse(item);
    this.certificateCode = item.certificateCode;
    this.labId = item.labId;
  },
  methods: {
    async chaxunHand() {
      this.$global.loading.show();
      try {
        let item = {
          labId: this.labId,
          securityCode: this.securityCode,
        };
        let res = await cateApi.tickets(item);
        this.type = 1;
        this.show = true;
        // try {
        //   let response = await cateApi.chaxun(res.data.ticket);
        //   this.$global.loading.hide();
        //   let data = response.data;
        //   if (data) {
        //     this.$toast("验证成功");
        //   } else {
        //     this.$toast("验证失败");
        //   }
        // } catch (error) {
        //   this.$global.loading.hide();
        //   console.log(error);
        //   this.$toast("访问失败");
        // }
      } catch (error) {
        console.log(error);
        this.type = 2;
        this.show = true;
      }
    },
    codeHand() {
      this.active = 0;
    },
    securityHand() {
      this.active = 1;
    },
    backChange() {
      this.show = false;
      this.type = 0;
      window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-con {
  width: 100%;
  height: 100%;
  background: #fff;

  .image-con {
    width: 100%;
    img {
      width: 100%;
    }
  }
  ::v-deep .van-tabs {
    width: 100%;
    height: 100%;
    padding: 0.4rem;
    box-sizing: border-box;
    .van-tabs__wrap {
      display: none;
    }
  }
  .active_toggle {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .active_item {
      background: #f1f1f1;
      border: 0;
      border-radius: 0.2rem;
      box-shadow: 0 0 0.06rem #999;
      padding: 0.3rem 0;
      text-align: center;
      width: 40%;
      p {
        color: #666;
        font-weight: 600;
        font-size: 0.36rem;
      }
    }
    .active {
      background: #33bd7d;
      box-shadow: 0 0 0.06rem #666666;
      color: #fff;
      p {
        color: #fff;
      }
    }
  }
  .showBindCode {
    margin-bottom: 0.6rem;
    width: 100%;
    padding: 0 0.4rem;
    box-sizing: border-box;
    input {
      border: 1px solid #eee;
      border-radius: 0.2rem;
      display: block;
      padding: 0.3rem 0.4rem;
      //   margin: 0 auto;
      width: 88%;
      background-color: #fafafa;
      margin-top: 0.4rem;
    }
  }
  .showBindCode:nth-child(2) {
    input {
      background-color: #ffffff;
    }
  }
  .searchBtn {
    background: #33bd7d;
    border-radius: 0.34rem;
    color: #fff;
    text-align: center;
    border: 1px #33bd7d solid;
    margin: 0.2rem;
    margin-top: 0.4rem;
    box-shadow: 0 0 0.1rem #999;
    p {
      width: 88%;
      padding: 0.2rem;
      font-weight: 600;
      font-size: 0.36rem;
    }
  }
}
.popup-con {
  width: 5rem;
  height: 6rem;
  border: 4px solid #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.64rem;
  img {
    width: 100%;
    height: 3rem;
    margin-bottom: 0.6rem;
  }
  h1 {
    &:nth-child(even) {
      font-weight: bold;
      font-size: 0.256rem;
      color: #0b1920;
      margin-bottom: 0.08rem;
    }
    &:nth-child(odd) {
      font-weight: 400;
      font-size: 0.192rem;
      color: #0b1920;
    }
  }
  .primary {
    margin-top: 0.6rem;
  }
}
.suc {
  background: linear-gradient(180deg, #e8fbff 0%, #ffffff 31%, #ffffff 100%);
}
.fail {
  background: linear-gradient(180deg, #fffde8 0%, #ffffff 31%, #ffffff 100%);
}
</style>
